import objectHandle from '../../utils/baseLib/objectHandle';

export const INITIALSTATE = "INITIALSTATE";
export const SHOWPAGE = "SHOWPAGE";

const initialState: any = {
  apiId: ''
}

/** 提交动作 */
export const InitData = (payload: any) => {
  return {
    type: INITIALSTATE,
    payload,
  };
}

/** 获取数据 */
export const ShowPage = (payload: any) => {
  return {
    type: SHOWPAGE,
    payload,
  };
}

export const showPageReducer = (state: any = initialState, action: { type: any, payload: any }) => {
  switch (action.type) {
    case INITIALSTATE:
      //如获取地址不同，初始化state
      let mergeData = objectHandle.mergeObject(state, action.payload);
      return {
        ...mergeData,
      };
    case SHOWPAGE:
      return {
        ...state,
        ...action.payload,
      };
    default: return state;
  }
}