import conf_prod from './configureStore.prod';
import conf_dev from './configureStore.dev';

const configureStore = () => {
  if (process.env.REACT_APP_NODE_ENV === 'production') {
    return conf_prod()
  } else {
    return conf_dev();
  }
}

export default configureStore;

