import objectHandle from '../baseLib/objectHandle';

async function request(method: string, url: string,
    body?: any, query?: any, headers?: any, currentToken?: string) {
    method = method.toUpperCase();

    if (method === 'GET') {
        body = undefined;
    } else {
        body = body && JSON.stringify(body);
    }

    if (query) {
        let paramsArray: string[] = [];
        //拼接参数
        Object.keys(query).forEach(key =>
            paramsArray.push(key + '=' + (objectHandle.isObject(query[key]) ? JSON.stringify(query[key]) : query[key])))
        if (url.search(/\?/) === -1) {
            url += '?' + paramsArray.join('&')
        } else {
            url += '&' + paramsArray.join('&')
        }
    }

    //修改
    let token = currentToken ? currentToken : (localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token')) as string;
    const res = await fetch(url, {
        method,
        headers: {
            credentials: 'include',
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'authorization': 'Bearer ' + token,
            ...headers
        },
        body
    });
    if (res.status >= 200 && res.status < 300) {
        const data = await res.json();
        return data;
    }
    else {
        if (res.status === 401) {
            //修改
            // antMessage('error', '未登录', "401，Token 未登录!");
            // localStorage.clear();
            // window.location.href = "/login"
        }
        const data = await res.json();
        if (method === 'GET') {
            // antMessage('error', '获取数据失败', data.message);
        }
        return Promise.reject(data)
        // return Promise.reject('请求失败！错误:' + res.statusText + '！错误代码:' + res.status +
        //     ' ' + res.url);
    }
}
/**
 * fetchApi 数据接口，包含 GET、POST、PUT、DELETE
 */
export default async function fetchApi() {
};

/**
 * fetchApi GET 
 * @param path 请求地址
 * @param query query 查询内容
 * @param headers headers头
 * @param token token值
 * @returns 返回获取的数据
 */
fetchApi.GET = (path: string, query?: any, headers?: any, token?: string) =>
    request('GET', path, undefined, query, headers, token);
/**
 * fetchApi POST
 * @param path 请求地址
 * @param body body 消息的内容
 * @param query query 查询内容
 * @param headers headers头
 * @param token token值
 * @returns 返回获取的数据
 */
fetchApi.POST = (path: string, body?: any, query?: any, headers?: any, token?: string) =>
    request('POST', path, body, query, headers, token);
/**
 * fetchApi PUT
 * @param path 请求地址
 * @param body body 消息的内容
 * @param query query 查询内容
 * @param headers headers头
 * @param token token值
 * @returns 返回获取的数据
 */
fetchApi.PUT = (path: string, body?: any, query?: any, headers?: any, token?: string) =>
    request('PUT', path, body, query, headers, token);
/**
 * fetchApi DELETE
 * @param path 请求地址
 * @param body body 消息的内容
 * @param query query 查询内容
 * @param headers headers头
 * @param token token值
 * @returns 返回获取的数据
 */
fetchApi.DELETE = (path: string, body?: any, query?: any, headers?: any, token?: string) =>
    request('DELETE', path, body, query, headers, token);


export const httpGet = (path: string, query?: any) => request('GET', path, undefined, query,);
export const httpPost = (path: string, body?: string | undefined, query?: any) => request('POST', path, body, query);
export const httpPut = (path: string, body?: string | undefined, query?: any) => request('PUT', path, body, query);
export const httpDel = (path: string, body?: string | undefined, query?: any) => request('DELETE', path, body, query);