import { Button, Form, Input, Select, Space, DatePicker, TimePicker, Image, Carousel, message } from 'antd';
import React, { useState } from 'react';
import moment from 'moment';
import fetchApi from '../utils/api/fetchApi';
//请求路径
import system from '../config/systematic';
import getList from '../utils/extend/getList';
import { RangePickerProps } from 'antd/lib/date-picker';
//基础图片
import minghao from '../image/minghao.jpg';

//时间选择器

const format = 'HH:mm';

const { Option } = Select;

const Reserve: React.FC = () => {
  //数据更新的判断
  let loading = false;
  /** 后端数据wmService */
  let wmService: any = system.wmService;
  //选择后日期数据
  const [flag, setFlag] = useState<any>(false);
  const [rows, setRows] = useState<any>();
  const [days, setDays] = useState<any>(30);
  //选择时段后数据]
  const [flags, setFlags] = useState<any>(true);
  const [dataTime, setdataTime] = useState<any>();
  const [datato, setdataTo] = useState<any>();
  const [flagTo, setFlagTo] = useState<any>(true);
  //电话号码和邮箱选填一个
  const [contact, setContact] = useState<any>(false);
  //图片展示
  const [roomStyle, setRoomStyle] = useState<any>([minghao]);
  //选房型后预付定金的提示
  const [deposit, setDeposit] = useState<any>({isDeposit:false,deposit:0});
  const [form] = Form.useForm();
  //提交信息
  const onFinish = (values: any) => {
    // console.log('Received values of form: ', values);
    console.log({
      roomTypeId: values.address.province,//房间类型 必填
      bookingNum: datato[values.address.street].item, //预订人数 必填
      contact: values.username, //联系人 必填
      contactCall: values.phone, //联系电话 必填
      timeId: values.time, //市别id 必填
      memo: values.introduction,//备注 选填
      arriveTime: values.gotime._d,//到达时间 必填
      dataSource: 0,//数据来源 必填
    });   
    fetchApi.POST(wmService, {
      apiId: "webCustomerReserve",
      apiExtend: 'create',
      apiData: {
        roomTypeId: values.address.province,//房间类型 必填
        bookingNum: datato[values.address.street].item, //预订人数 必填
        contact: values.username, //联系人 必填
        contactCall: values.phone, //联系电话 必填
        timeId: values.time, //市别id 必填
        memo: values.introduction,//备注 选填
        arriveTime: values.gotime._d,//到达时间 必填
        dataSource: 0,//数据来源 必填
        shopId: "ed9f0c80033f11edbf98a55b5b9a029d"
      }
    }).then(res => {
      console.log(res);
      if(res.success){
        message.success({
          content: '预定成功',
          className: 'custom-class',
          style: {
            marginTop: '50vh'
          },
        });
      }
    })
  };

  // eslint-disable-next-line arrow-body-style
  const disabledDate: RangePickerProps['disabledDate'] = current => {
    // Can not select days before today and today
    // eslint-disable-next-line no-mixed-operators
    return current && current < moment().subtract(1, 'days').endOf('day') || moment().subtract(-days, 'days').endOf('day') < current;
  };

  //邮箱校检
  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  /* eslint-enable no-template-curly-in-string */

  //拼接图片
  const splicing = (data: any) => {
    let urlData: any[] = []
    data.roomTypeFiles.forEach((item: any) => {
      urlData.push(data.filesServer + "/" + item.destination + "/" + item.fileName)
    });
    return urlData
  }

  //获取数据的方法
  const getData = async (bookingDate: any,) => {
    if (loading === true) { return }
    loading = true;
    fetchApi.GET(wmService, {
      apiId: "webCustomerReserve",
      apiExtend: 'getDateToTimeData',
      apiData: {
        bookingDate,
        shopId: "ed9f0c80033f11edbf98a55b5b9a029d"
      }
    }).then(res => {
      console.log(res);
      if (res.success === true) {
        setRows(res.data);
        loading = false;
        if (res.data.length === 0) {
          setFlag(false);
        } else {
          setFlag(true);
        }
      }
    })
  }

  //用户选择日期后获取当天可订的午市晚市
  const handleDate = (date: any) => {
    console.log(date);
    if (date) {
      getData(moment(date._d).format("YYYY-MM-DD"))
      form.resetFields(["time"]);
    } else {
      setFlag(false);
    }
  };

  //用户选择午市晚市后获取当天可订的房型和到店时间段
  const setTime = (timeId: any) => {
    console.log(timeId);
    form.setFieldsValue({ time: timeId })
    fetchApi.GET(wmService, {
      apiId: "webCustomerReserve",
      apiExtend: 'getDateToRoomData',
      apiData: {
        timeId,
        shopId: "ed9f0c80033f11edbf98a55b5b9a029d"
      }
    }).then(res => {
      // console.log(res);
      if (res.success === true) {
        if (res.data.length === 0) {
          setFlags(true);
        } else {
          //打开选择房型的开关
          setFlags(false);
          //最早到店时间
          setStartTime(res.data.startTime);
          //最晚到店时间
          setEndTime(res.data.endTime);
          let data: any[] = []
          res.data.data.forEach((elemen: any) => {
            let url = splicing(elemen);//拼接后的图片
            if (elemen.bookingRoomNum > 0) {
              elemen.url = url
              data.push(elemen)
            }
          });
          console.log(data);
          setdataTime(data)
        }
      }
    })
  }
  //选择房型后
  const setTo = (toId: any) => {
    form.setFieldsValue({ province: toId })
    let data: any[] = []
    dataTime.forEach((item: any) => {
      if (item.id === toId) {
        setRoomStyle(item.url);//插入图片
        setDeposit({isDeposit:item.isDeposit,deposit:item.deposit})//预支付定金提示
        setFlagTo(false);
        range(item.containMinNum, item.containMaxNum + 1).forEach((element: any) => {
          data.push({ "item": element, })
        })
        setdataTo(data);
      }
    });
  }
  //禁用时间
  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  //到店时间选择的范围
  const [startTime, setStartTime] = useState<any>(0);
  const [endTime, setEndTime] = useState<any>();

  //到店时间
  const goTime = (gotime: any) => {
    form.setFieldsValue({ gotime: gotime })
  }

  //禁止选择的到店时间--时分
  const disabledDateTime = () => ({
    disabledHours: () => range(0, 24).splice(0, startTime).concat(range(0, 24).splice(endTime, 24 - endTime)),
    disabledMinutes: () => range(60, 60),
    disabledSeconds: () => [55, 56],
  });

  //填电话
  const phone = async (e: any,) => {
    let email = form.getFieldValue("email")
    if (!email) { setContact(false); }
  }
  //填邮箱
  const email = async (e: any,) => {
    let phone = form.getFieldValue("phone")
    if (!phone) { setContact(true); }
  }

  return (
    <><Form form={form} name="complex-form" onFinish={onFinish} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} validateMessages={validateMessages} style={{ width: 330, height: 600, position: "relative" }}>
      <Form.Item label="日期/Date" style={{ margin: 0 }}>
        <Form.Item
          name="date"
          rules={[{ required: true }]}
        >
          <DatePicker
            disabledDate={disabledDate}
            format="YYYY-MM-DD"
            onChange={handleDate}
            style={{ width: 200 }} />
        </Form.Item>
      </Form.Item>
      <Form.Item label="时段/Period">
        <Form.Item
          name="time"
          noStyle
          rules={[{ required: true }]}>
          {flag ? <div>
            <Select onChange={setTime} placeholder="请选择时间段" style={{ width: 200 }}>
              {rows.map((item: any) => {
                return <Option value={item.id}>{item.name}</Option>;
              })}
            </Select>
          </div> :
            <Select defaultValue="lucy" style={{ width: 200 }} disabled>
              <Option value="lucy">请先选择日期</Option>
            </Select>}
        </Form.Item>
      </Form.Item>
      <Form.Item label="房型/Room size">
        <Input.Group compact>
          <Form.Item
            name={['address', 'province']}
            noStyle
            rules={[{ required: true, message: 'Province is required' }]}
          >
            <Select
              onChange={setTo}
              placeholder="房型"
              style={{ width: 100 }}
              optionFilterProp="children"
              filterOption={(input, Option: any) => Option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              disabled={flags}
            >
              {getList.general(dataTime, 'roomType')}
            </Select>
          </Form.Item>
          <Form.Item
            name={['address', 'street']}
            noStyle
            rules={[{ required: true, message: 'Please enter the number of people' }]}
          >
            <Select
              placeholder="人数"
              style={{ width: 100 }}
              optionFilterProp="children"
              filterOption={(input, Option: any) => Option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              disabled={flagTo}
            >
              {getList.general(datato, "item")}
            </Select>
          </Form.Item>
        </Input.Group>  
        {deposit.isDeposit?<div  style={{color:"#b6c2c9", fontSize:"8px", margin:"0 0", position:"absolute",width:"100%"}}>该房型需要付定金{deposit.deposit}</div>:''}       
      </Form.Item>
      <Form.Item label="到店时间/To time" style={{ margin: 0 }}>
        <Form.Item
          name="gotime"
          rules={[{ required: true }]}
        >
          <TimePicker
            onChange={goTime}
            value={moment(format)}
            style={{ width: 200 }}
            placeholder='请选择到店时间'
            format={format}
            disabledTime={disabledDateTime}
            hideDisabledOptions={true}
            disabled={flagTo} />
        </Form.Item>
      </Form.Item>
      <Form.Item label="姓名/Name">
        <Space>
          <Form.Item
            name="username"
            noStyle
            rules={[{ required: true, message: 'Name is required' }]}
          >
            <Input maxLength={20} style={{ width: 200 }} placeholder="请输入姓名" />
          </Form.Item>
        </Space>
      </Form.Item>
      <Form.Item label="电话/Telephone" style={{ marginBottom: 0 }}>
        <Form.Item
          name="phone"
          rules={[{ required: !contact, message: "Enter phone or email" }]}
          style={{ display: 'inline-block', width: 200 }}
        >
          <Input type="number" maxLength={15} onChange={phone} placeholder="输入电话号码" />
        </Form.Item>
      </Form.Item>
      <Form.Item name='email' label="邮箱/Email" rules={[{ type: 'email', required: contact, message: "Enter phone or email" }]}>
        <Input onChange={email} style={{ width: 200 }} />
      </Form.Item>
      <Form.Item name='introduction' label="备注/Remarks">
        <Input.TextArea
          style={{ width: 200, marginLeft:9 }}
          showCount
          autoSize={{ minRows: 2, maxRows: 3 }}
          maxLength={150} />
      </Form.Item>
      <Form.Item label=" " colon={false}>
        <Button type="primary" htmlType="submit">
          立即预定
        </Button>
      </Form.Item>
    </Form>
      <div style={{ position: "absolute", top: 50, left: 470, width: 740 }}>
        <Carousel autoplay>
          {roomStyle.map((item: any) => {
            return <div><Image width={740} src={item} /></div>;
          })}
        </Carousel></div>
    </>
  );
};

export default Reserve;