import { takeEvery, put, select, call } from 'redux-saga/effects';
import { INITIALSTATE, ShowPage } from '../../store/system/showPage'
import fetchApi from '../../utils/api/fetchApi'

export function* showPageAsync(): Generator<any, any, any> {
    /** 获取state中的showPageData */
    const showPageData = yield select(state => state.showPageData)
    let { apiId } = showPageData;
    // if (typeof apiId === 'undefined') {
    //     apiId = apiName;
    // }
    // showPageData.apiName = apiId;
    // showPageData.apiId = undefined;
    /** req.query */
    const query = {
        apiId,
        apiExtend: 'showPage',
        apiData: {
            current: showPageData[apiId].data.current,
            pageSize: showPageData[apiId].data.pageSize,
            queryString: showPageData[apiId].data.queryString,
            orderByString: showPageData[apiId].data.orderByString,
            //Request query 传送数组方法:
            //前台传送时JSON.stringify(数组对象),后台JSON.parse(数组对象)
            sendPageData: showPageData[apiId].sendPageData
        }
    };
    console.log('***当前请求数据***');
    console.log(query);
    console.log('************');
    try {
        const returnData = yield call(
            fetchApi.GET,
            showPageData[apiId].fetchUrl,
            query)
        let sendPageData = {} as any;
        sendPageData[apiId] = returnData;
        sendPageData[apiId].fetchUrl = showPageData[apiId].fetchUrl;
        sendPageData[apiId].sendPageData = showPageData[apiId].sendPageData;
        const payload: any = {
            ...showPageData,
            ...sendPageData
        }
        yield put(ShowPage(payload));
    }
    catch (err: any) {
        console.log('showPageSaga Error:');
        console.log(err);
        //权限
        switch (err.errors.status) {
            case 401: //未登录
                // window.location.href = '/login';
                break;
            default:
                break;
        }
    }
}

export function* watchShowPageAsync() {
    yield takeEvery(INITIALSTATE, showPageAsync);
}