import './AppLayout.css';
import { Dimensions } from "react-native";
import { useEffect, useState } from 'react';
import Reserve from './components/reserve';
import { Modal, Carousel, Typography, Divider } from "antd";

import img from './image/main.jpg'

import logo from './image/logo.png'
import menu from './image/menu.png'

import e from './image/e1.jpg'
import ee from './image/e2.jpg'
import eee from './image/e3.jpg'
import eeee from './image/e4.jpg'
import eeeee from './image/e5.jpg'
import eeeeee from './image/e6.jpg'
import eeeeeee from './image/e7.jpg'
import eeeeeeee from './image/e8.jpg'
import eeeeeeeee from './image/e9.jpg'
import eeeeeeeeee from './image/e10.jpg'

import f from './image/f1.jpg'
import ff from './image/f2.jpg'
import fff from './image/f3.jpg'
import ffff from './image/f4.jpg'
import fffff from './image/f5.jpg'
import ffffff from './image/f6.jpg'
import fffffff from './image/f7.jpg'
import ffffffff from './image/f8.jpg'
import fffffffff from './image/f9.jpg'
import ffffffffff from './image/f10.jpg'

import books from './image/books.jpg'
import gifts from './image/gifts.jpg'
import abouts from './image/abouts.jpg'


import food from './image/food.png'
import environment from './image/environment.png'
import booking from './image/booking.png'
import gift from './image/gift.png'
import about from './image/about.png'

const { Paragraph } = Typography;

const AppNoble = (props: any) => {

    const [openModal, setOpenModal] = useState(false);
    const [iea, setIE] = useState("index");

    let screenWidth = Dimensions.get('window').width;
    let screenHeight = Dimensions.get('window').height;

    console.log("screenWidth" + screenWidth);
    console.log("screenHeight" + screenHeight);

    useEffect(() => {
        const fetchData = async () => {

        };
        fetchData();

        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openmodal = () => {
        setOpenModal(true);
    }

    return (
        <>
            {iea === "index" ? (
                <div style={{ textAlign: 'center', overflowX: 'hidden' }}>
                    <a onClick={() => setIE("food")}><img height={screenHeight} src={img} /></a>
                </div>
            ) : null}
            {iea === "food" ? (
                <div style={{ textAlign: 'center', overflowX: 'hidden' }}>
                    <Carousel dotPosition="right" effect="fade" autoplay>
                        <div>
                            <img height={screenHeight} src={f} />
                        </div>
                        <div>
                            <img height={screenHeight} src={ff} />
                        </div>
                        <div>
                            <img height={screenHeight} src={fff} />
                        </div>
                        <div>
                            <img height={screenHeight} src={ffff} />
                        </div>
                        <div>
                            <img height={screenHeight} src={fffff} />
                        </div>
                        <div>
                            <img height={screenHeight} src={ffffff} />
                        </div>
                        <div>
                            <img height={screenHeight} src={fffffff} />
                        </div>
                        <div>
                            <img height={screenHeight} src={ffffffff} />
                        </div>
                        <div>
                            <img height={screenHeight} src={fffffffff} />
                        </div>
                        <div>
                            <img height={screenHeight} src={ffffffffff} />
                        </div>
                    </Carousel>
                    <img
                        alt="top"
                        src={menu}
                        style={{
                            position: "fixed",
                            cursor: "pointer",
                            left: "60px",
                            top: "60px"
                        }}
                        onClick={() => openmodal()}
                    />
                    <Modal
                        mask={false}
                        maskClosable={true}
                        closable={false}
                        visible={openModal}
                        onCancel={() => setOpenModal(false)}
                        width={230}
                        footer={null}
                        style={{
                            position: "fixed",
                            cursor: "pointer",
                            left: "60px",
                            top: "130px",
                            borderRadius: "20px",
                            overflow: "auto"
                        }}
                    >
                        <p style={{ textAlign: 'center' }}><a onClick={() => setIE("index")}><img src={logo} /></a></p>
                        <p style={{ textAlign: 'center' }}><a onClick={() => setIE("food")}><img src={food} /></a></p>
                        <p style={{ textAlign: 'center' }}><a onClick={() => setIE("environment")}><img src={environment} /></a></p>
                        <p style={{ textAlign: 'center' }}><a onClick={() => setIE("booking")}><img src={booking} /></a></p>
                        <p style={{ textAlign: 'center' }}><a onClick={() => setIE("gift")}><img src={gift} /></a></p>
                        <p style={{ textAlign: 'center' }}><a onClick={() => setIE("about")}><img src={about} /></a></p>
                    </Modal>
                </div>
            ) : null}
            {iea === "environment" ? (
                <div style={{ textAlign: 'center', overflowX: 'hidden' }}>
                    <Carousel dotPosition="right" effect="fade" autoplay>
                        <div>
                            <img height={screenHeight} src={e} />
                        </div>
                        <div>
                            <img height={screenHeight} src={ee} />
                        </div>
                        <div>
                            <img height={screenHeight} src={eee} />
                        </div>
                        <div>
                            <img height={screenHeight} src={eeee} />
                        </div>
                        <div>
                            <img height={screenHeight} src={eeeee} />
                        </div>
                        <div>
                            <img height={screenHeight} src={eeeeee} />
                        </div>
                        <div>
                            <img height={screenHeight} src={eeeeeee} />
                        </div>
                        <div>
                            <img height={screenHeight} src={eeeeeeee} />
                        </div>
                        <div>
                            <img height={screenHeight} src={eeeeeeeee} />
                        </div>
                        <div>
                            <img height={screenHeight} src={eeeeeeeeee} />
                        </div>
                    </Carousel>
                    <img
                        alt="top"
                        src={menu}
                        style={{
                            position: "fixed",
                            cursor: "pointer",
                            left: "60px",
                            top: "60px"
                        }}
                        onClick={() => openmodal()}
                    />
                    <Modal
                        mask={false}
                        maskClosable={true}
                        closable={false}
                        visible={openModal}
                        onCancel={() => setOpenModal(false)}
                        width={230}
                        footer={null}
                        style={{
                            position: "fixed",
                            cursor: "pointer",
                            left: "60px",
                            top: "130px",
                            borderRadius: "20px",
                            overflow: "auto"
                        }}
                    >
                        <p style={{ textAlign: 'center' }}><a onClick={() => setIE("index")}><img src={logo} /></a></p>
                        <p style={{ textAlign: 'center' }}><a onClick={() => setIE("food")}><img src={food} /></a></p>
                        <p style={{ textAlign: 'center' }}><a onClick={() => setIE("environment")}><img src={environment} /></a></p>
                        <p style={{ textAlign: 'center' }}><a onClick={() => setIE("booking")}><img src={booking} /></a></p>
                        <p style={{ textAlign: 'center' }}><a onClick={() => setIE("gift")}><img src={gift} /></a></p>
                        <p style={{ textAlign: 'center' }}><a onClick={() => setIE("about")}><img src={about} /></a></p>
                    </Modal>
                </div>
            ) : null}
            {iea === "booking" ? (
                <div style={{ textAlign: 'center', overflowX: 'hidden' }}>
                    <img height={screenHeight} src={books} />
                    <div style={{ width: "1200px", height: "600px", top: '25%', left: '210px', display: 'flex', position: "absolute", backgroundColor: "#ffffff" }}>
                        <div style={{ height: '600px', margin: '50px 80px' }}><Reserve /></div>
                    </div>
                    <img
                        alt="top"
                        src={menu}
                        style={{
                            position: "fixed",
                            cursor: "pointer",
                            left: "60px",
                            top: "60px"
                        }}
                        onClick={() => openmodal()}
                    />
                    <Modal
                        mask={false}
                        maskClosable={true}
                        closable={false}
                        visible={openModal}
                        onCancel={() => setOpenModal(false)}
                        width={230}
                        footer={null}
                        style={{
                            position: "fixed",
                            cursor: "pointer",
                            left: "60px",
                            top: "130px",
                            borderRadius: "20px",
                            overflow: "auto"
                        }}
                    >
                        <p style={{ textAlign: 'center' }}><a onClick={() => setIE("index")}><img src={logo} /></a></p>
                        <p style={{ textAlign: 'center' }}><a onClick={() => setIE("food")}><img src={food} /></a></p>
                        <p style={{ textAlign: 'center' }}><a onClick={() => setIE("environment")}><img src={environment} /></a></p>
                        <p style={{ textAlign: 'center' }}><a onClick={() => setIE("booking")}><img src={booking} /></a></p>
                        <p style={{ textAlign: 'center' }}><a onClick={() => setIE("gift")}><img src={gift} /></a></p>
                        <p style={{ textAlign: 'center' }}><a onClick={() => setIE("about")}><img src={about} /></a></p>
                    </Modal>
                </div>
            ) : null}
            {iea === "gift" ? (
                <div style={{ textAlign: 'center', overflowX: 'hidden' }}>
                    <img height={screenHeight} src={gifts} />
                    <img
                        alt="top"
                        src={menu}
                        style={{
                            position: "fixed",
                            cursor: "pointer",
                            left: "60px",
                            top: "60px"
                        }}
                        onClick={() => openmodal()}
                    />
                    <Modal
                        mask={false}
                        maskClosable={true}
                        closable={false}
                        visible={openModal}
                        onCancel={() => setOpenModal(false)}
                        width={230}
                        footer={null}
                        style={{
                            position: "fixed",
                            cursor: "pointer",
                            left: "60px",
                            top: "130px",
                            borderRadius: "20px",
                            overflow: "auto"
                        }}
                    >
                        <p style={{ textAlign: 'center' }}><a onClick={() => setIE("index")}><img src={logo} /></a></p>
                        <p style={{ textAlign: 'center' }}><a onClick={() => setIE("food")}><img src={food} /></a></p>
                        <p style={{ textAlign: 'center' }}><a onClick={() => setIE("environment")}><img src={environment} /></a></p>
                        <p style={{ textAlign: 'center' }}><a onClick={() => setIE("booking")}><img src={booking} /></a></p>
                        <p style={{ textAlign: 'center' }}><a onClick={() => setIE("gift")}><img src={gift} /></a></p>
                        <p style={{ textAlign: 'center' }}><a onClick={() => setIE("about")}><img src={about} /></a></p>
                    </Modal>
                </div>
            ) : null}
            {iea === "about" ? (
                <div >
                    <div style={{ margin: 'auto', width: '' + screenWidth / 2 + 'px', textAlign: 'center' }}>
                        <br /><br />
                        <img src={abouts} />
                        <br /><br /><br />

                        <Typography>
                            <Paragraph style={{ lineHeight: '2.3', fontSize: '18px', color: '#003f98', textAlign: 'left' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;上海名豪创立于2000年7月8日。立足于上海这座国际大都市，结合上海未来发展的高度，以全球顶级服务理念，背靠全国，面向世界，海纳百川，诠释“法式粤菜”的服务方式和饮食品质。运用国际化语言，为全球宾客提供“百年上海的至尊礼遇”。
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;全球化的上海，亦东亦西。无论是总统、总理的荣耀光临，还是尊贵的您，上海名豪一贯精选优质食材，融汇东西烹调技艺，致力于简单烹调理念，朴素自然的摆盘风格，宽阔的迎宾空间及用餐环境，使宾客感受独一无二、超现实的超级上海名豪！
                            </Paragraph>

                            <Divider />

                            <Paragraph style={{ lineHeight: '2', fontSize: '18px', color: '#e4007f', textAlign: 'left' }}>
                                Noble Seafood's Shanghai branch was founded on July 8th, 2000. It has been deeply rooted in this international metropolis by its understanding of China’s national conditions and global vision that closely parallels the development of modern Shanghai. With its high-quality food and service, in a fusion of French and Cantonese cuisine, the restaurant is presenting the courteous reception from the centennial Shanghai to customers worldwide.
                                <br />
                                Shanghai welcomes global visitors and adapts both oriental and western lifestyle. Shanghai Noble Seafood also impresses guests from all over the world by its topnotch ingredients, all-embracing cooking style, back-to-basics culinary philosophy, minimalist plating style, and spacious dining area. Whether you are a nation leader or a normal visitor, you will be led to a unique and surreal journey here.
                            </Paragraph>
                        </Typography>

                        <img
                            alt="top"
                            src={menu}
                            style={{
                                position: "fixed",
                                cursor: "pointer",
                                left: "60px",
                                top: "60px"
                            }}
                            onClick={() => openmodal()}
                        />
                        <Modal
                            mask={false}
                            maskClosable={true}
                            closable={false}
                            visible={openModal}
                            onCancel={() => setOpenModal(false)}
                            width={230}
                            footer={null}
                            style={{
                                position: "fixed",
                                cursor: "pointer",
                                left: "60px",
                                top: "130px",
                                borderRadius: "20px",
                                overflow: "auto"
                            }}
                        >
                            <p style={{ textAlign: 'center' }}><a onClick={() => setIE("index")}><img src={logo} /></a></p>
                            <p style={{ textAlign: 'center' }}><a onClick={() => setIE("food")}><img src={food} /></a></p>
                            <p style={{ textAlign: 'center' }}><a onClick={() => setIE("environment")}><img src={environment} /></a></p>
                            <p style={{ textAlign: 'center' }}><a onClick={() => setIE("booking")}><img src={booking} /></a></p>
                            <p style={{ textAlign: 'center' }}><a onClick={() => setIE("gift")}><img src={gift} /></a></p>
                            <p style={{ textAlign: 'center' }}><a onClick={() => setIE("about")}><img src={about} /></a></p>
                        </Modal>
                    </div>
                </div>
            ) : null}
        </>

        // <div style={{ textAlign: 'center', overflowX: 'hidden' }}>

        //     {/* 这是首页的内容 */}
        //     {/* <img height={screenHeight} src={img}/> */}



        //     {/* 以下部门是美食的内容 */}
        //     <Carousel dotPosition="right" effect="fade" autoplay>
        //         <div>
        //             <img height={screenHeight} src={ev} />
        //         </div>
        //         <div>
        //             <img height={screenHeight} src={ve} />
        //         </div>
        //         <div>
        //             <img height={screenHeight} src={ev} />
        //         </div>
        //         <div>
        //             <img height={screenHeight} src={ve} />
        //         </div>
        //         <div>
        //             <img height={screenHeight} src={ev} />
        //         </div>
        //     </Carousel>
        //     <img
        //         alt="top"
        //         src={menu}
        //         style={{
        //             position: "fixed",
        //             cursor: "pointer",
        //             left: "60px",
        //             top: "60px"
        //         }}
        //         onClick={() => openmodal()}
        //     />
        //     <Modal
        //         mask={false}
        //         maskClosable={true}
        //         closable={false}
        //         visible={openModal}
        //         onCancel={() => setOpenModal(false)}
        //         width={230}
        //         footer={null}
        //         style={{
        //             position: "fixed",
        //             cursor: "pointer",
        //             left: "120px",
        //             top: "130px",
        //             borderRadius: "20px",
        //             overflow: "auto"
        //         }}
        //     >
        //         <p style={{ textAlign: 'center' }}><img src={logo} /></p>
        //         <p style={{ textAlign: 'center' }}><img src={food} /></p>
        //         <p style={{ textAlign: 'center' }}><img src={environment} /></p>
        //         <p style={{ textAlign: 'center' }}><img src={booking} /></p>
        //         <p style={{ textAlign: 'center' }}><img src={gift} /></p>
        //         <p style={{ textAlign: 'center' }}><img src={about} /></p>
        //     </Modal>
        // </div>
    )
};

export default AppNoble;