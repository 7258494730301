import AppLayout from '../AppLayout'
import { RouteObject } from "react-router-dom"

//system
import Main from '../components/Main';



const routers: RouteObject[] = [
  {
    path: "/",
    element: <AppLayout></AppLayout>,
    children: [
      {
        element: <Main />,
        index: true
      },

    ]
  }
]
let indexRouters = routers.map((item: any, index: number) => {
  if (item.children) {
    return { ...item, children: item.children }
  } else {
    return { ...item }
  }
})


export default indexRouters;