import { Suspense } from 'react';
import { useRoutes } from "react-router-dom";
import './App.css';
// import routers from './routers/defRouter';
import routers from './routers/index'
const App = () => { 
  return (
    <Suspense fallback={<>loading</>}>
      {useRoutes(routers)}
    </Suspense>
  )
}
export default App;