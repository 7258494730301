import React from 'react'
import img from '../image/main.jpg'

const Welcome = () => {

    return (

        <img width="100%" height="100%" src={img} />

    )

}

export default Welcome;