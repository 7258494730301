import { Select } from "antd";
import fetchApi from "../../utils/api/fetchApi";
import system from '../../config/systematic';
import objectHandle from '../../utils/baseLib/objectHandle';

const { Option } = Select;
/** 后端数据wmService */
const wmService = system.wmService;

/**
 * 由数据集生成列表Option
 */
const getList = () => {
};

/**
 * 获取通用列表
 * @param rows 数据集
 * @param itemName ? Option.item.name，默认[name]
 * @param itemId ? Option.item.id，默认[id]
 * @param noId ? 没有id键的数组
 * @returns Option 
 */
getList.general = (rows: any, itemName?: string, itemId?: string, noId?: boolean) => {
      
    if (typeof rows !== "undefined") {

        if (rows.constructor === Object) {
            rows = [rows]
        } else {
            // eslint-disable-next-line no-self-assign
            rows = rows
        }
        if (rows.length === undefined) { return undefined }

        return (rows.map((item: any, key: number) => {
                         
            return <Option value={
                noId === true ?
                    item
                    :
                    typeof itemId === 'undefined' ? item.id : item[itemId]
            }
                key={key}>
                {noId === true ?
                    item
                    :
                    typeof itemName === 'undefined' ? item.name : item[itemName]
                }
            </Option>
        }))
    }
};

/**
 * react 查询
 * @param apiId 接口名称
 * @param setData react useState => setData
 * @param queryString 查询的内容
 * @returns 列表 | undefined
 */
getList.search = async (apiId: string, setData: any, queryString?: string,) => {
    if (objectHandle.isEmpty(queryString) === true) {
        console.log('查询不能为空');
        return undefined;
    }
    const res = await fetchApi.GET(wmService,
        {
            apiId,
            apiExtend: 'showList',
            apiData: {
                queryString
            }
        })
    if (res.success === true) {
        setData(res.data);
        console.log('查询到:');
        console.log(res.data);
    }
    return setData;
};

export default getList;