// import "dotenv/config";

/** 开发模式 */
const isDevelopmentEnvironment = process.env.REACT_APP_NODE_ENV === "development";

/** 文件服务器 */
const serverUrl = (isDevelopmentEnvironment
    ? process.env.REACT_APP_DEV_FILESSERVER
    : process.env.REACT_APP_FILESSERVER)
    || "http://localhost:3009";

/** 文件服务器API */
const serverApiUrl = serverUrl + '/' +
    (isDevelopmentEnvironment
        ? process.env.REACT_APP_DEV_FILESURL
        : process.env.REACT_APP_FILESURL)

/** 后端数据api */
const apiUrl =
    (isDevelopmentEnvironment
        ? process.env.REACT_APP_DEV_APIURL
        : process.env.REACT_APP_APIURL)
    || "http://localhost:3003/test";

/** 后端数据wmsApi */
const wmsApiUrl =
    (isDevelopmentEnvironment
        ? process.env.REACT_APP_DEV_WMSAPIURL
        : process.env.REACT_APP_WMSAPIURL)
    || "http://localhost:3005/test";

/** 地址配置文件 */
const systematic = {
    /** 后端数据api */
    apiUrl,
    /** 后端数据bmService */
    bmService: apiUrl + '/wm/service',
    /** 后端数据wmService*/
    wmService: wmsApiUrl + "/wx/service",
    /** 文件服务器 */
    filesServer: {
        /** 文件服务器地址 */
        serverUrl,
        /** 服务id */
        serviceId: process.env.REACT_APP_FILESERVICEID,
        /** 文件服务器api地址 */
        filesApiUrl:
            serverApiUrl + '/' + process.env.REACT_APP_FILEEXTENDURL
            || "http://localhost:3009/test/file/service",
        /** 文件服务器api管理地址 */
        filesManageApiUrl:
            serverApiUrl + '/' + process.env.REACT_APP_FILEMANAGEEXTENDURL
            || "http://localhost:3009/test/manage/service",
    }
};

export default systematic;
